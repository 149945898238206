import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Cta from "../components/cta";
import Hero from "../components/hero";

import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

function PrivacyPolicy({ data }) {
    let d = data.contentfulPageRich;
    return (
        <Layout>
            <SEO title={d.title} />

            <Hero
                props={{
                    padding: `10`,
                    title: d.title,
                    desc: ``,
                }}
            />

            <section className="bg-white dark:bg-gray-800">
                <div className="w-full max-w-5xl mx-auto px-4 py-8 md:px-8 md:py-16 paragraph">
                    {renderRichText(d.content)}
                </div>
            </section>

            <Cta />
        </Layout>
    );
}

PrivacyPolicy.propTypes = {
    data: PropTypes.object,
};

export const query = graphql`
    {
        contentfulPageRich(label: { eq: "privacy_policy" }) {
            title
            label
            content {
                raw
            }
        }
    }
`;

export default PrivacyPolicy;
